#ReferFriends{
  .overview{
    .ant-tabs-tab-btn{
      line-height: 1rem;
    }
    &-container{
      width:100%;
      margin-bottom: 16px;
      background: #FFFFFF;
      &-info{
        padding: 16px 8px;
        &-id{
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
        &-qr{
          width: 150px;
          height: 150px;
          margin: 20px auto 0;
        }
        .rose{
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.25rem;
          color: rgba(6, 23, 49, 0.75);
          margin-bottom: 16px;
          &-left{
              font-size: 1rem;
              line-height: 1.25rem;
              font-weight: 700;
              color: #092249;
            }
        }
        &-content{
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          margin-bottom: 16px;
          &-line{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
          }
        }
        &-btn{
          margin: 28px auto 0;
          width: 220px;
          height: 40px;
          padding: 10px 24px;
          background: #0073FF;
          border-radius: 8px;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.25rem;
          text-align: center;
          color: #FFFFFF;
          cursor:pointer;
        }
      }
    }
    }
    .left{
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .right{
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.25rem;
      &-red{
        color: red;
      }
      &-blue{
        font-style: italic;
        font-weight: 400;
        font-size: 1rem;
        text-decoration-line: underline;
        color: #4062ED;
        overflow: hidden;
        text-overflow: ellipsis;
        width:95%;
        white-space: nowrap;
      }
      &-img{
        margin-top: -3px;
        margin-left: 8px;
        width: 24px;
        height:24px;
        cursor:pointer;
      }
    }
    .left1{
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .right1{
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  .history{
    left: calc(50% - 304px/2);
    top: 104px;
    background: #FFFFFF;
    border: 1px solid rgba(174, 185, 199, 0.2);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin: 0 8px 20px;
    overflow-y: auto;
    table{
      text-align: center;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
      width:100%;
      white-space: nowrap;
    }
    &-title{
      width: 100%;
      background: #F8F8F8;
      td{
        padding: 8px 12px;
      }
    }
    .nodata{
      height: 144px;
      width:211px;
      margin: auto;
    }
  }
  .team-title{
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 8px 12px;
    background: #EEEEEE;
    color: rgba(6, 23, 49, 0.75);
  }
  .calendar {
    display:flex;
    justify-content: center;
    padding: 20px;
    &-img {
      width: 17px;
      height: 19px;
    }
    &-text{
      color: #C82215;
      padding-left: 8px;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      font-weight: 400;
    }
   }
  tbody > tr > td {
    padding: 4px
  }
}
