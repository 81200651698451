@import "../../../../../assets/styles/helpers/variables";
.container {
  &-footer {
    position: fixed;
    width: 100%;
    max-width: 384px;
    padding: 13px 36px;
    height: 60px;
    z-index: 99;
    border-top: 1px solid #70B3F9;
    bottom: 0;
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    @media #{$media-ipad} {
      max-width: 100vw;
    }
    &__item {
      text-align: center;
      cursor: pointer;
      .footer {
        &-icon {
          width: 24px;
          height: 24px;
          margin: 0 auto 4px;
        }
        &-label {
          color: $main-light-color;
          font-weight: 700;
          font-size: 11px;
        }
      }
      &.active {
        .footer {
          &-label {
            color: $main-color;
          }
        }
      }
    }
  }
}