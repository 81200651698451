$media-tablet-horizontal: "only screen and (max-width : 1160px)";
$media-tablet-vertical: "only screen and (max-width : 900px)";
$media-ipad: "only screen and (max-width : 769px)";
$media-small-phone: "only screen and (max-width : 320px)";
$media-small-fold: "only screen and (max-width : 281px)";

$main-color: #0073FF;
$main-light-color: #70B3F9;
$text-color: #333333;
$dark-main-color: #19463D;
$light-main-color: #005E4B;
$background-linear-main: linear-gradient(270deg, #0073FF 0%, #0073FF 13.02%, #141ED2 100%);

$black-text-color:#092249;
$header-color: #fff;

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-box;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

@mixin display-flex-important {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -moz-box !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
}