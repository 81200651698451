@import '../../../../../assets/styles/helpers/variables';
.container {
  &-header {
    @include display-flex;
    align-items: center;
    justify-content: space-between;
    background: $background-linear-main;
    position: fixed;
    width: 100%;
    max-width: 384px;
    padding: 12px 8px;
    height: 48px;
    z-index: 99;
    @media #{$media-ipad} {
      max-width: 100vw;
    }
  }
  &-item {
    &__center {
      font-weight: 700;
      font-size: 15px;
      color: $header-color;
    }
  }
  &-menu-toggle {
    width: 16px;
    height: 14px;
    @include display-flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    .bar {
      height: 2px;
      border-radius: 10px;
      background-color: #FFFFFF;
      margin-top: 4px;
      transition: 0.3s ease;
      &:first-child {
        margin-top: 0;
      }
      &--top {
        width: 16px;
      }
      &--middle{
        width: 10px;
      }
      &--bottom {
        width: 16px;
      }
    }

    .topBar {
      width: 12px;
    }

    .middleBar {
      width: 12px;
    }

    .bottomBar {
      width: 24px;
    }
  }
  &-icon {
    position: relative;
    width: 24px;
    height: 24px;
    background-size: contain !important;
    cursor: pointer;
    .notification {
      position: absolute;
      background-color: #FFBE59;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      padding-bottom: 0;
      right: 0;
      top: -2px;
      opacity: 0;
      &.active {
        opacity: 1;
      }
    }
    .shop-cart {
      position: absolute;
      background-color: #FFBE59;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      padding-bottom: 0;
      right: -2px;
      top: 0;
      opacity: 0;
      &.active {
        opacity: 1;
      }
    }
  }
}
.icon {
  &-back {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}