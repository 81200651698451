@import "../assets/styles/helpers/variables";
.background {
  &-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.btn {
  &-main {
    background: $main-color !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    padding: 10px !important;
    height: 40px !important;
    line-height: 1 !important;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      background: $main-color !important;
      opacity: .8 !important;
    }
  }
  &-red {
    background: #F5493A !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    padding: 6px !important;
    height: 32px !important;
    line-height: 1 !important;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      background: #F5493A !important;
      opacity: .8 !important;
    }
  }
  &-outline {
    &-main {
      background: #fff !important;
      color: $main-color !important;
      border-radius: 8px !important;
      border: 1px solid $main-color;
      padding: 6px !important;
      height: 32px !important;
      line-height: 1 !important;
      font-size: 14px;
      font-weight: 700;
      &:hover {
        background: #fff !important;
        opacity: .8 !important;
      }
    }
  }
}
.container {
 &-input {
   position: relative;
   .input {
     border: 1px solid $main-color;
     border-radius: 8px;
     text-align: center;
     height: 40px;
     &:hover, &:focus {
       outline: none;
       box-shadow: none;
     }
     &::placeholder {
       color: #AEB9C7;
       opacity: 1; /* Firefox */
     }

   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
   }
 }
}