.branch {
  &__header {
    background: linear-gradient(90deg, #C72114 27.6%, #F5493A 100%);
    border-radius: 0px 0px 24px 24px;
    height: 320px;
    position: relative;
  }
  &__detail{
    padding-top: 27px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-align: center;
  }
  &__card{
    position: absolute;
    width: calc(100% - 32px);
    height: 240px;
    left: 16px;
    bottom: -82px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 16px 12px;
  }
  &__box{
    background: linear-gradient(107.34deg, #70B3F9 17.19%, #4E86F4 100%);
    border-radius: 12px;
    height: 152px;
    width: 100%;
    padding: 14px 18px;
    color: #FFFFFF;
    &__lable{
      font-size: 12px;
    }
    &__title{
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &__des{
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      margin-top: 14px;
      display: flex;
      align-items: center;
      svg{
        margin-left: auto;
      }
    }
    &__line{
      margin-top: 12px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 12px;
    }
    &__text{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
    }
    &__div{
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &__footer {
    margin-top: 110px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin: 110px 12px 12px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 28px 20px;
    &__second{
      margin-top: 20px;
      padding: 12px;
    }
    &__item{
      cursor: pointer;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    color: var(--white-color);
    align-items: center;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 16px;
    }
    &-right {
      margin-left: 12px;
      p {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  .branch-title {
    p.amount {
      max-width: 130px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

  }

  .management__box  {
    padding-bottom: 88px;
  }
  .icon {
    color: #ED3C37;
    margin-left: 6px;
    cursor: pointer;
    svg {
      margin-top: -10px;
    }
  }
  &__search {
    .ant-input-affix-wrapper {
      width: 60%;
      border-color: #DBDDDD;
      border-radius: 1px;
    }
  }
  .ant-table-wrapper {
    .ant-table-thead {
      .ant-table-cell {
        background: #F8F8F8 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }
    .ant-table-tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
}