#introduction-board{
  margin-bottom: 40px;
  .introduction{
    margin: 0 8px 20px 8px;
    background: #FFFFFF;
    border: 1px solid rgba(174, 185, 199, 0.2);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    &-container{
      overflow: auto;
      padding: 12px 0;
      &-title{
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: rgba(6, 23, 49, 0.75);
        padding: 0 0 8px 12px;
      }
      &-tr{
        td{
          background: #F8F8F8;
          padding:8px;
        }

      }
      &-tr1{
        td{
          padding-top: 8px;
        }

      }
    }
    table{
      text-align: center;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
      width:100%;
      white-space: nowrap;
    }
  }
}
